<template>
  <div class="contain">
    <div class="mainContent">
      <el-form ref="checkForm" :model="formInline" label-width="100px">
        <el-row class="search">
          <el-col :span="18">
            <el-row>
              <el-col :span="6">
                <el-form-item label="机构名称:" prop="orgName">
                  <el-input v-model="formInline.orgName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="上级机构:">
                  <selectOrg
                    placeholder="选择上级机构"
                    ref="selectOrg"
                    @select="selectOrg"
                  ></selectOrg>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="reset">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button type="primary" @click="add">新增</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div class="jy_table">
        <ctable
          ref="ctable"
          :columns="columns"
          :data="tableData"
          :total="total"
          :btnList="btnList"
          operateWidth="200"
          @cellClick="cellBtnClick"
          @update="initTable"
        >
        </ctable>
      </div>
    </div>
    <!-- 新增编辑 -->
    <cdialog
      :show="showDialog"
      :columns="columns"
      title="机构"
      width="30%"
      labelWidth="120px"
      ref="cdialog"
      :data="selData"
      showDouble
      :isEdit="isEdit"
      @close="closeDialog"
      @verify="saveOrUpdate"
      @upSuccess="uploadLogoSuccess"
    >
      <template slot="pOrgId">
        <selectOrg
          placeholder="选择上级机构"
          :currentOrgName="selData.pOrgName"
          ref="selectSlotOrg"
          @select="selectSlotOrg"
        ></selectOrg>
      </template>
    </cdialog>
  </div>
</template>

<script>
import selectOrg from "@/components/dialog/selectOrg";
import { getToken } from "@/utils/token";
export default {
  name: "index",
  mounted() {
    this.$nextTick(() => {
      this.initTable();
    });
  },
  methods: {
    search() {
      this.$refs.ctable.reset();
      this.getList();
    },
    initTable() {
      this.getList();
    },
    reset() {
      this.formInline = {
        orgName: "",
        pOrgId: "",
        orgType: "",
      };
      this.$refs.selectOrg.reset();
      this.$refs.ctable.reset();
      this.getList();
    },
    getList() {
      const page = this.$refs.ctable.getPager();
      this.$http({
        url: "/sys/org/queryPage",
        data: {
          ...this.formInline,
          ...page,
        },
      })
        .then((res) => {
          this.tableData = res.detail.list;
          this.total = res.detail.total;
        })
        .catch(() => {});
    },
    selectOrg(data) {
      this.formInline.pOrgId = data.orgId;
    },
    selectSlotOrg(data) {
      const form = this.$refs.cdialog.getFormData();
      this.$set(form, "pOrgId", data.orgId);
    },
    cellBtnClick(index, btnIndex, item) {
      if (btnIndex === "edit") {
        this.selData = item;
        this.showDialog = true;
        this.isEdit = true;
      } else {
        this.$confirm("是否删除此机构", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (item.hasChild == 1) {
            this.$message.warning({
              message: "此机构有子机构，请先删除子机构！",
              duration: 1000,
            });
            return false;
          }
          this.$http({
            url: "/sys/org/delete",
            data: {
              ids: item.orgId,
            },
          }).then((res) => {
            if (res && res.code === "0") {
              this.$message.success({
                message: "删除成功",
                type: "success",
                duration: 1000,
              });
            }
            this.getList();
          });
        });
      }
    },
    closeDialog() {
      this.selData = {};
      this.orgName = "";
      if (this.$refs.selectSlotOrg) {
        this.$refs.selectSlotOrg.reset();
      }
      this.isEdit = false;
      this.showUpload = false;
      this.showDialog = false;
    },
    async saveOrUpdate(data) {
      if (this.isEdit) {
        if (data.parentId != data.orgId) {
          this.$http
            .post({
              url: "/sys/org/update",
              data,
            })
            .then((response) => {
              if (response.code === "0") {
                this.showDialog = false;
                this.$message({
                  message: "更新成功",
                  type: "success",
                  duration: 1000,
                });
                this.getList();
              }
            });
        } else {
          this.$message.warning({
            message: "上级机构设置错误!",
            duration: 3000,
          });
        }
      } else {
        this.$http({
          url: "/sys/org/save",
          data,
        }).then((response) => {
          if (response.code === "0") {
            this.showDialog = false;
            this.$message({
              message: "新增成功",
              type: "success",
              duration: 1000,
            });
            this.getList();
          } else {
            this.$message({
              message: response.error,
              type: "error",
              duration: 1000,
            });
          }
        });
      }
    },
    add() {
      this.showDialog = true;
      this.isEdit = false;
    },
    importInto() {
      this.showUpload = true;
    },
    exportData() {
      this.showUpload = true;
    },
    uploadSuccess() {
      this.showUpload = false;
      this.initTable();
    },
    uploadLogoSuccess(res) {
      const form = this.$refs.cdialog.getFormData();
      this.$set(form, "logo", res.msg);
    },
  },
  data() {
    return {
      formInline: {
        orgName: "",
        pOrgId: "",
        orgType: "",
      },

      total: 0,
      showDialog: false,
      isEdit: false,

      selData: {},

      tableData: [],
      columns: [
        {
          prop: "name",
          title: "机构名称",
          tag: "el-input",
        },
        {
          prop: "orgType",
          title: "机构类型",
          tag: "el-select",
          formatter: (index, key, item) => {
            return item["orgTypeName"];
          },
          options: [
            {
              value: 1,
              name: "机构",
            },
            {
              value: 2,
              name: "线路",
            },
          ],
        },
        {
          prop: "pOrgId",
          title: "上级机构",
          tag: "slot",
          formatter: (index, key, item) => {
            return item["pOrgName"];
          },
        },
        {
          prop: "contactPerson",
          title: "联系人",
          tag: "el-input",
          unNeed: true
        },
        {
          prop: "phone",
          title: "联系电话",
          tag: "el-input",
          unNeed: true
        },
        {
          prop: "logo",
          title: "LOGO",
          tag: "el-upload",
          unNeed: true,
          props: {
            action: "/emergency-admin/sys/user/uploadLogo",
            headers: {
              token: getToken(),
            },
          },
          image: true,
          span: 24,
          tip: "图片高度不大于60px，宽度不大于500，且大小不超过5MB!",
        },
        {
          title: "备注",
          prop: "remark",
          tag: "el-input",
          props: {
            type: "textarea",
          },
          unNeed: true,
          span: 24,
        },
        {
          prop: "createTime",
          title: "创建日期",
        },
      ],
      showUpload: false,
      // loadFunc: () => {
      //   return this.$http({
      //     responseType: "blob",
      //     url: "/sys/org/template",
      //     method: "post",
      //   });
      // },

      btnList: [
        {
          index: "edit",
          title: "编辑",
        },
        {
          index: "delete",
          title: "删除",
        },
      ],

      options: [],
      orgTypeList: [
        {
          value: 1,
          label: "机构",
        },
        {
          value: 2,
          label: "线路",
        },
      ],
    };
  },
  components: {
    selectOrg,
  },
//   watch: {
//     isEdit() {
//       if (this.isEdit) {
//         this.$set(this.columns[1], "tag", "");
//       } else {
//         this.$set(this.columns[1], "tag", "slot");
//       }
//     },
//   },
};
</script>
